import * as React from "react"
import * as Icon from 'react-feather'
import styled from "styled-components"

import Contentpage from "../components/templates/Contentpage"
import Seo from '../components/atoms/Seo'
import Row from "../components/molecules/Row"
import Column from "../components/atoms/Column"
import Title from "../components/atoms/Title"
import Button from '../components/atoms/Button'

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ContactPage = () => {
  return (
    <Contentpage>
      <Seo
      title='Contact us'
      description='Contact Pedley Law PLLC'
      />
      
      <Row>
          <Column size='20%'>
            <Title level={3} color='#de5827' position='left'>DENVER, CO</Title>
            <StyledList>
              <li>4610 S. Ulster St. Suite 150</li>
              <li>Denver, CO 80237</li>
              <li>Telephone: 720-445-7744</li>
              <li>Fax: 502-214-3121</li>
            </StyledList>
            <Button position='left' background={'#df5828'} backgroundHover={'#c94f24'} link="mailto:dpedley@pedleylaw.com?Subject=Contact%20from%20website" externalLink={true}>
                  <Icon.Mail size={24} color={'#fff'} style={{margin: '0 8px -6px 0'}} /> Email us
              </Button>
          </Column>
      </Row>
    </Contentpage>
  )
}

export default ContactPage
