import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledInternalButton = styled(Link)`
    text-decoration: none;
    background: ${props => props.background};
    color: #fff;
    font-family: 'DINNextLTPro-Regular';
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    padding: 16px 8px;
    text-align: center;
    display: block;
    margin: ${props => props.position === 'center' ? '16px auto' : '16px 0'};
    width: 200px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &:visited,
    &:focus,
    &:active,
    &:focus,
    &:focus-within,
    &:link:active,
    &:visited:active {
        color: #fff;
    }

    &:hover {
        color: #fff;
        background: ${props => props.hover};
    }
`;
const StyledExternalButton = styled.a`
    text-decoration: none;
    background: ${props => props.background};
    color: #fff;
    font-family: 'DINNextLTPro-Regular';
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    padding: 16px 8px;
    text-align: center;
    display: block;
    margin: ${props => props.position === 'center' ? '16px auto' : '16px 0'};
    width: 200px;

    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    &:visited,
    &:focus,
    &:active,
    &:focus,
    &:focus-within,
    &:link:active,
    &:visited:active {
        color: #fff;
    }

    &:hover {
        color: #fff;
        background: ${props => props.hover};
    }
`;

const Button = ({background, backgroundHover, position, link, externalLink, children}) => {
    return (
        <>
        {
            externalLink ?
            <StyledExternalButton background={background} hover={backgroundHover} href={link} position={position}>
                {children}
            </StyledExternalButton> :
            <StyledInternalButton background={background} hover={backgroundHover} to={link} position={position}>
                {children}
            </StyledInternalButton>
        }
        </>
    )
}

export default Button